// @flow
import * as React from 'react'
import styled from 'styled-components'
import { rem } from 'polished'

import type { QuoteViewModel } from '../../types/QuoteViewModel'

import { mediaOnly } from '../../styles/media'
import { getLineHeight, getFontSize } from '../../styles/style-helpers'

type Props = QuoteViewModel & {
  children?: React.Node,
  size?: 'normal' | 'small',
  isArticleContent?: boolean,
  isAccordionContent?: boolean,
  invert?: boolean,
}

const BlockquoteWrapper = styled.blockquote`
  padding: 0;
  margin: 0;
`

const QuoteParagraph = styled.p`
  margin: 0;
  padding: 0;
  font-weight: bold;
  font-size: ${p => (p.size === 'small' ? rem(24) : rem(28))};
  color: ${({ theme, invert }) => (invert ? theme.textInvert : theme.text)};
  line-height: ${p =>
    p.size === 'small' ? getLineHeight('small') : getLineHeight('normal')};
  position: relative;

  &::after {
    color: ${({ theme }) => theme.quote};
    content: ',,';
    position: absolute;
    display: block;
    font-weight: bold;
    font-size: ${rem(61)};
    line-height: 1;
    font-family: sans-serif;
    right: 0;
    bottom: -${rem(30)};
  }

  &::before {
    color: ${({ theme }) => theme.quote};
    content: '“';
    position: absolute;
    display: block;
    font-weight: bold;
    font-size: ${rem(61)};
    line-height: 1;
    font-family: sans-serif;
    margin-left: -${rem(50)};

    ${({ isAccordionContent }) =>
      isAccordionContent
        ? `
      max-height: ${rem(35)};
      position: relative;
      top: 0;
      left: 0;
      margin-left: 0;
      overflow: hidden;
    `
        : null}

    ${mediaOnly.xs`
      max-height: ${rem(35)};
      position: relative;
      top: 0;
      left: 0;
      margin-left: 0;
      overflow: hidden;
    `};

    ${mediaOnly.sm`
      max-height: ${rem(35)};
      position: relative;
      top: 0;
      left: 0;
      margin-left: 0;
      overflow: hidden;
    `};
  }

  ${mediaOnly.xs`
    font-size: ${p => (p.size === 'small' ? rem(18) : rem(22))};
    line-height: ${p => (p.size === 'small' ? '1.5' : '1.36')};
  `};

  ${mediaOnly.sm`
    font-size: ${p => (p.size === 'small' ? rem(18) : rem(22))};
    line-height: ${p => (p.size === 'small' ? '1.5' : '1.36')};
  `};
`

// @TODO - font-weight needs to be 300 here, is not supported yet
const Cite = styled.cite`
  margin-top: ${rem(24)};
  text-align: left;
  font-style: normal;
  display: block;
  font-size: ${getFontSize('small')};
  color: ${({ theme }) => theme.text};
`

function Blockquote({
  children,
  quote,
  cite,
  size,
  isAccordionContent,
  invert,
}: Props) {
  return (
    <BlockquoteWrapper>
      <QuoteParagraph
        invert={invert}
        isAccordionContent={isAccordionContent}
        size={size}
      >
        {quote || children}
      </QuoteParagraph>
      {cite && <Cite>{cite}</Cite>}
    </BlockquoteWrapper>
  )
}

Blockquote.displayName = 'Blockquote'
Blockquote.defaultProps = {
  size: 'normal',
}

export default Blockquote
