// @flow
import * as React from 'react'
import styled from 'styled-components'
import consecutive from 'consecutive'
import { rem } from 'polished'

import { color } from '../../styles/theme'
import { mediaQuery, mediaOnly } from '../../styles/media'
import { renderAccordionItemChildren } from '../../components/AccordionElements/helpers'

import type { AccordionViewModel } from '../../types/AccordionViewModel'

import AccordionContainer from '../../components/AccordionElements/AccordionContainer'
import AccordionItem from '../../components/AccordionElements/AccordionItem'
import { AccordionMarkdown } from '../../components/AccordionElements/AccordionMarkdown'
import Ribbon from '../../components/Ribbon/Ribbon'
import Heading from '../../components/Heading/Heading'
import Markdown from '../../components/Markdown/Markdown'
import { HyphenatedText } from '../../components/HyphenatedText/HyphenatedText'
import useId, { IdProvider } from '@charlietango/use-id'

export type Props = AccordionViewModel

const keyGen = consecutive()

const Wrapper = styled.div`
  margin-bottom: ${rem(40)};

  ${mediaQuery.md`
    margin-bottom: ${rem(50)};
  `}
`

const AccordionOuter = styled.div`
  ${({ boxed, theme, area }) =>
    boxed &&
    `
    position: relative;
    padding-top: ${area === 'article' ? rem(84) : 0};
    padding-bottom: ${area === 'article' ? rem(160) : 0};
    background: ${theme.bgColor};
  `}

  ${mediaQuery.lg`
    margin-right: -${({ boxed, area }) =>
      boxed && area === 'article' ? rem(50) : 0};
  `}

  ${mediaQuery.hd`
    margin-right: -${({ boxed, area }) =>
      boxed && area === 'article' ? rem(100) : 0};
  `}

  ${({ area, boxed }) =>
    boxed &&
    area === 'wide' &&
    `
    padding-top: 0;
    padding-bottom: 0;
  `}
`

const AccordionInner = styled.div`
  ${({ boxed, theme, area }) =>
    boxed &&
    `
    border-radius: ${area === 'wide' ? rem(theme.borderRadius) : 0};
    padding: ${rem(48)} 0;
    background: ${theme.body};
    color: ${color.white};

    svg {
      color: ${color.accent};
    }
  `}

  ${mediaQuery.sm`
    border-radius: ${({ boxed, theme }) =>
      boxed ? rem(theme.borderRadius) : 0};
    padding: ${({ boxed }) => (boxed ? rem(96) : 0)} 0;
  `}
`

const TopFakeBorder = styled.div`
  display: none;

  ${({ visible, theme }) =>
    visible &&
    `
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    height: ${rem(20)};
    width: 100%;
    background: ${color.white};
    border-bottom-left-radius: ${rem(theme.borderRadius)};
    border-bottom-right-radius: ${rem(theme.borderRadius)};
  `}

  ${mediaOnly.xs`
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
  `}
`

const BottomFakeBorder = styled.div`
  display: none;

  ${({ visible, theme }) =>
    visible &&
    `
    display: block;
    position: absolute;
    bottom: 0;
    left: 0;
    height: ${rem(96)};
    width: 100%;
    background: ${color.white};
    border-top-left-radius: ${rem(theme.borderRadius)};
    border-top-right-radius: ${rem(theme.borderRadius)};
  `}

  ${mediaOnly.xs`
    border-top-left-radius: 0;
    border-top-right-radius: 0;
  `}
`

const DescriptionText = styled(Markdown)`
  && {
    margin: 0;
  }
`

const AccordionText = styled(AccordionMarkdown)`
  && {
    margin-bottom: ${rem(48)};
    color: ${({ invert, theme }) => (invert ? theme.textInvert : theme.text)};
  }
`

const StyledHeading = styled(Heading).attrs({ level: 2 })`
  color: ${({ invert, theme }) => (invert ? theme.textInvert : theme.primary)};
  ${({ area }) =>
    area === 'narrow' &&
    `
    font-size: ${rem(20)};
   `}
`

const generateStructuredData = items => {
  const entities =
    items &&
    items.map(item => {
      const texts = [item.text]
      if (item.items) {
        item.items.forEach(({ markdown, quote }) => {
          if (markdown && markdown.source)
            texts.push(`<p>${markdown.source}</p>`)
          if (quote && quote.quote && quote.cite)
            texts.push(`<p>${quote.quote} - ${quote.cite}</p>`)
        })
      }

      return `{
        "@type": "Question",
        "name": "${item.title || ''}",
        "acceptedAnswer": {
          "@type": "Answer",
          "text": "${texts.join(' ')}"
        }
      }`
    })

  return (
    `{
    "@context": "https://schema.org",
    "@type": "FAQ",` +
    // $FlowFixMe this is to ignore type issue in flow. I'm changing types (array into string), and flow doesn't like that.
    ` "mainEntity": [${entities}]
  }`
  )
}

const Accordion = ({ items, title, boxed, text, anchor, area }: Props) => {
  const uniqueId = useId('accordion')
  const initial = undefined
  const WrapperEl = boxed && area === 'wide' ? Ribbon : Wrapper
  const InnerWrapperEl = area === 'narrow' ? React.Fragment : Ribbon
  return (
    <IdProvider>
      <WrapperEl
        id={(anchor && anchor.id) || `${uniqueId}-accordion-${keyGen()}`}
      >
        <AccordionOuter area={area} boxed={boxed}>
          <TopFakeBorder visible={boxed && area === 'article'} />
          <AccordionInner area={area} boxed={boxed}>
            <InnerWrapperEl>
              {title && (
                <StyledHeading invert={boxed} area={area}>
                  <HyphenatedText text={title} minWordLength={4} />
                </StyledHeading>
              )}
              {text && <DescriptionText source={text} invert={boxed} />}
              <AccordionContainer initial={initial}>
                {items && items.length
                  ? items.map((item, index) => (
                      <AccordionItem
                        invert={boxed}
                        key={`${uniqueId}-ModuleAccordionItem-${keyGen()}`}
                        id={`${uniqueId}-${index}-${keyGen()}`}
                        title={item.title || ''}
                        cta={item.link}
                      >
                        {item && item.text && (
                          <AccordionText
                            invert={boxed}
                            source={item.text}
                            noMargin
                          />
                        )}
                        {item.items &&
                          renderAccordionItemChildren(item.items, boxed)}
                      </AccordionItem>
                    ))
                  : null}
              </AccordionContainer>
            </InnerWrapperEl>
          </AccordionInner>
          <BottomFakeBorder visible={boxed && area === 'article'} />
        </AccordionOuter>
      </WrapperEl>
      <script type="application/ld+json">
        {generateStructuredData(items)}
      </script>
    </IdProvider>
  )
}

Accordion.displayName = 'Accordion'
Accordion.defaultProps = {}
export default Accordion
