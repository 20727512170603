// @flow
import * as React from 'react'

import type { TableViewModel } from '../../types/TableViewModel'

import Table from '../Table/Table'
import Grid from '../Grid/Grid'

export const AccordionTable = (props: TableViewModel) => (
  <Grid>
    <Table {...props} />
  </Grid>
)
