// @flow
import * as React from 'react'
import styled from 'styled-components'

import type { QuoteViewModel } from '../../types/QuoteViewModel'

import Blockquote from '../Blockquote/Blockquote'

const AccordionQuoteWrapper = styled.div`
  padding-left: 0;
  padding-right: 0;
`

export const AccordionQuote = (props: QuoteViewModel) => (
  <AccordionQuoteWrapper>
    <Blockquote {...props} />
  </AccordionQuoteWrapper>
)
