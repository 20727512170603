// @flow
import * as React from 'react'
import styled from 'styled-components'
import { getFontSize, getLineHeight } from '../../styles/style-helpers'
import { rem } from 'polished'

import type { AccordionImageViewModel } from '../../components/AccordionElements/types/AccordionImageViewModel'

import Image from '../Image/Image'
import Grid from '../Grid/Grid'

const CaptionContainer = styled.div`
  margin-top: ${rem(4)};
`

const CaptionContainerItem = styled.p`
  font-size: ${getFontSize('small')};
  margin-bottom: 0;
  margin-top: 0;
  font-weight: 300;
  line-height: ${getLineHeight('normal')};
  color: ${(props: Props) => (props.invert ? 'white' : 'currentColor')};
`

type Props = AccordionImageViewModel & { invert?: boolean }

export const AccordionImage = ({ image, caption, credit, invert }: Props) => (
  <Grid>
    <Image {...image} />
    {(caption || credit) && (
      <CaptionContainer>
        {caption && (
          <CaptionContainerItem invert={invert}>{caption}</CaptionContainerItem>
        )}
        {credit && (
          <CaptionContainerItem invert={invert}>{credit}</CaptionContainerItem>
        )}
      </CaptionContainer>
    )}
  </Grid>
)
