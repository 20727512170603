// @flow
import * as React from 'react'

import type { MarkdownViewModel } from '../../types/MarkdownViewModel'

import Markdown from '../Markdown/Markdown'
import Grid from '../Grid/Grid'

export const AccordionMarkdown = (props: MarkdownViewModel) => (
  <Grid>
    <Markdown {...(props || {})} />
  </Grid>
)
