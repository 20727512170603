// @flow
import * as React from 'react'
import styled from 'styled-components'
import consecutive from 'consecutive'
import { rem } from 'polished'

import type { AccordionItemChildViewModel } from './types/AccordionItemChildViewModel'

import { AccordionMarkdown } from './AccordionMarkdown'
import { AccordionImage } from './AccordionImage'
import { AccordionQuote } from './AccordionQuote'
import { AccordionTable } from './AccordionTable'

const keyGen = consecutive()

const AccordionItemChild = styled.div`
  margin-bottom: ${rem(48)};
  color: ${({ theme, invert }) => (invert ? theme.textInvert : theme.text)};
`

export const renderAccordionItemChildren = (
  items: Array<AccordionItemChildViewModel>,
  boxed?: boolean,
) => {
  const panels = []

  items.forEach(item => {
    switch (item.type) {
      case 'markdown':
        panels.push(
          item.markdown ? (
            <AccordionItemChild key={`AccordionItemChild-${keyGen()}`}>
              <AccordionMarkdown invert={boxed} {...item.markdown} noMargin />
            </AccordionItemChild>
          ) : null,
        )
        break
      case 'image':
        panels.push(
          item.image ? (
            <AccordionItemChild key={`AccordionItemChild-${keyGen()}`}>
              <AccordionImage {...item.image} invert={boxed} />
            </AccordionItemChild>
          ) : null,
        )
        break
      case 'quote':
        panels.push(
          item.quote ? (
            <AccordionItemChild key={`AccordionItemChild-${keyGen()}`}>
              <AccordionQuote {...item.quote} invert={boxed} />
            </AccordionItemChild>
          ) : null,
        )
        break
      case 'table':
        panels.push(
          item.table ? (
            <AccordionItemChild key={`AccordionItemChild-${keyGen()}`}>
              <AccordionTable {...item.table} invert={boxed} />
            </AccordionItemChild>
          ) : null,
        )
        break
      default:
        break
    }
  })

  return <div>{panels}</div>
}
