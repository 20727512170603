// @flow
import * as React from 'react'
import styled from 'styled-components'
import { rem } from 'polished'
import { getFontSize, getLineHeight } from '../../styles/style-helpers'
import { effects } from '../../styles/theme'
import { mediaQuery } from '../../styles/media'

import type { TableViewModel } from '../../types/TableViewModel'

type Props = TableViewModel & { invert?: boolean }

const BottomnSpacer = styled.div`
  padding-bottom: ${rem(32)};
`

const TableContainer = styled.table`
  border-collapse: collapse;
  width: 100%;
  padding-bottom: ${rem(16)};
  color: ${({ theme, invert }) => (invert ? theme.textInvert : theme.text)};

  tbody tr {
    border-top: ${({ theme, invert }) =>
      invert
        ? effects(theme).borderPositiveInvert
        : effects(theme).borderPositive};
    line-height: ${rem(48)};

    :first-of-type {
      border-top: ${({ heading, theme, invert }) =>
        heading === 'column'
          ? invert
            ? effects(theme).borderPositiveInvert
            : effects(theme).borderPositive
          : 'none'};

      & > :first-child {
        padding-left: 0;
      }

      & > :last-child {
        padding-right: 0;
      }

      th {
        line-height: ${getLineHeight('small')};
        padding-right: ${rem(22)};
        padding-bottom: ${rem(16)};
        vertical-align: top;
      }
    }

    :not(:first-of-type) {
      th {
        line-height: ${getLineHeight('small')};
        padding: ${rem(16)} ${rem(22)} ${rem(16)} 0 ;
        vertical-align: top;
      }

      & > :first-child {
        padding-left: 0;
      }
    }

    :last-of-type {
      border-bottom: ${({ theme, invert }) =>
        invert
          ? effects(theme).borderPositiveInvert
          : effects(theme).borderPositive};
    }

    td {
      padding: ${rem(16)} ${rem(22)} ${rem(16)} 0;
      line-height: ${getLineHeight('small')};
    }

    & > :first-child {
      padding-left: 0;
    }

    & > :last-child {
      padding-right: 0;
    }
  }

  td,
  th {
    font-size: ${getFontSize('small')};
    text-align: left;
    color: ${({ theme, invert }) => (invert ? theme.textInvert : theme.text)};+
    ${mediaQuery.lg`
      min-width: ${rem(150)};
    `}
  }
`

const TableTitle = styled.caption`
  text-align: left;
  font-size: ${getFontSize('normal')};
  line-height: ${getLineHeight('small')};
  font-weight: 900;
  margin-bottom: ${rem(16)};
  color: ${({ theme, invert }) => (invert ? theme.textInvert : theme.text)};
`

function Table({ title, rows, heading, invert }: Props) {
  if (!rows || !rows.length) return null

  const renderTable = () => {
    return rows.map((row, rowKey) => (
      <tr key={rowKey}>
        {row.map((col, colKey) => {
          const ColTag = getColumnTag(rowKey, colKey)
          return <ColTag key={`${rowKey}-${colKey}`}>{col}</ColTag>
        })}
      </tr>
    ))
  }

  const getColumnTag = (rowKey: number, colKey: number) => {
    let ColTag = 'td'

    if (heading === 'row') {
      ColTag = rowKey === 0 ? 'th' : 'td'
    }

    if (heading === 'column') {
      ColTag = colKey === 0 ? 'th' : 'td'
    }

    if (heading === 'both') {
      ColTag = rowKey !== 0 ? (colKey === 0 ? 'th' : 'td') : 'th'
    }

    return ColTag
  }

  return (
    <>
      <TableContainer invert={invert} heading={heading}>
        {title ? <TableTitle invert={invert}>{title}</TableTitle> : null}

        <tbody>{renderTable()}</tbody>
      </TableContainer>
      <BottomnSpacer />
    </>
  )
}

Table.displayName = 'Table'
Table.defaultProps = {}

export default Table
